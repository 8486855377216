<template>
  <v-app id="app">
    <Header />

    <!-- <div class="wrapper">
      <div id="nav">
        <router-link to="/">Home</router-link> |
        <router-link to="/email">Email</router-link> |
        <router-link to="/tasks">Tasks</router-link>
      </div>
    </div>-->

    <router-view />

    <Footer />
    <Loading />
  </v-app>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Loading from "@/components/Loading";

export default {
  components: {
    Header,
    Footer,
    Loading,
  },
};
</script>

<style lang="scss">
@import "@/styles/_reset.scss";
@import "@/styles/_main.scss";

#app {
  font-family: $reg;
  color: $gray;

  .main_button {
    display: block;
    width: 250px;
    text-align: center;
    text-transform: none;
    margin: 50px auto 0;

    &.v-btn--disabled {
      span {
        color: $gray;
      }
    }

    &.hideBt {
      display: none !important;
    }

    span {
      color: #fff;
      letter-spacing: 0px;
    }
  }

  strong {
    font-family: $bold;
  }
}
</style>