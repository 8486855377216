import { pathifiedFullModule } from "@/store/helpers/pathifiedModule";

const state = {
  answers: [],
  fullAnswers: []
};

const mutations = {
  ADD_ANSWER: (state, value) => state.answers.push(value)
};

const actions = {
  addAnswer: ({ commit }, value) => commit("ADD_ANSWER", value)
};

export default pathifiedFullModule(state, null, mutations, actions);
