import { make } from "vuex-pathify";

export const pathifiedFullModule = (
  state,
  getters = null,
  mutations = null,
  actions = null
) => {
  return {
    namespaced: true,
    state,
    getters: {
      ...make.getters(state),
      ...getters
    },
    mutations: {
      ...make.mutations(state),
      ...mutations
    },
    actions: {
      ...make.actions(state),
      ...actions
    }
  };
};

export const pathifiedGetterModule = state => {
  return {
    namespaced: true,
    state,
    getters: {
      ...make.getters(state)
    }
  };
};
