<template>
  <div class="home">
    <div class="wrapper">
      <h1>Плануєте складати іспит A2&nbsp;Key (KET)?</h1>
      <p>Пройдіть наш інтерактивний тест і&nbsp;відразу дізнайтесь чи&nbsp;готові до&nbsp;екзамену.<br>Тест триватиме 10&nbsp;&mdash; 15&nbsp;хвилин.</p>
      <router-link to="/email">
        <v-btn elevation="2" color="#57b894" class="main_button">Спробувати</v-btn>
      </router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    // HelloWorld
  }
}
</script>

<style lang="scss">
.home {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  h1 {
    font-size: 42px;
    font-family: $bold;
    margin-bottom: 32px;
  }

  p {
    font-size: 18px;
    margin-bottom: 100px;
  }

  a {
    text-decoration: none;
  }
}
</style>
