import Vue from "vue";
import Vuex from "vuex";
import pathify from "vuex-pathify";
import { persistedState } from "./helpers/persistedState";
import modules from "./modules";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  plugins: [pathify.plugin, persistedState],
  modules
});
