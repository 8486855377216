<template>
  <v-footer
      absolute
      color="#394155"
    >
    <div class="wrapper">
        <div class="copiright">© 2008–{{ new Date().getFullYear() }} Grade Education Centre, Cambridge Assessment English (license UA007)</div>
    </div>
  </v-footer>
</template>

<script>
  export default {
    name: 'Footer',

    data: () => ({
        
    }),
  }
</script>


<style lang="scss">
.v-footer {padding: 0 !important;}
.copiright {
    color: #fff;
    font-size: 14px;
    padding: 15px 0 17px;
    text-align: left;
}
</style>