<template>
  <div class="header">
    <div class="wrapper">
        <div class="logo">
            <img src="@/assets/logo.svg" alt="">
            <span>Діагностичний тест А2 KEY</span>
        </div>

        <div class="created_by">
          Проект компанії <a href="https://grade.ua/" target="_blank">grade.ua</a>
        </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Header'
  }
</script>


<style lang="scss">
.header {
    background-color: #f0f0f0;
    padding: 13px 0px 14px;

    .wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        display: inherit;

        img {
          width: 23px;
          margin-right: 7px;
        }
      }

      .created_by {
        a {
          font-family: $bold;
          text-decoration: none;
          color: #394155;
        }
      }

      @media(max-width: 600px) {
        padding: 0 20px;
      }

      @media(max-width: 480px) {
        flex-direction: column;

        .logo {
          margin-bottom: 10px;
        }
      }
    }
}
</style>