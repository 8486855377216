import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/email",
    name: "Email",
    component: () => import("../views/Email.vue")
  },
  {
    path: "/tasks",
    name: "Tasks",
    component: () => import("../views/Tasks.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.get("user/email")) return next({ name: "Email" });
      return next();
    }
  },
  {
    path: "/finish",
    name: "Finish",
    component: () => import("../views/Finish.vue")
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
