<template>
  <v-overlay :value="loading" z-index="9999">
    <v-progress-circular indeterminate :size="100" color="#394155"></v-progress-circular>
  </v-overlay>
</template>

<script>
import { get } from "vuex-pathify";

export default {
  computed: {
    loading: get("app/loading"),
  },
};
</script>